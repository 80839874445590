import React from 'react'
import { Check } from 'react-feather'
import {
  SpecsSection,
  SpecsContainer,
  SpecContainer,
  SpecTitle,
  SpecList,
  ItemName,
} from './styles'

const Specifications = ({ specs }) => {
  return (
    <SpecsSection>
      <SpecsContainer>
        {specs.map((spec, i) => {
          return(
            <SpecContainer key={i}>
              <SpecTitle>{spec.node.title}</SpecTitle>
              <SpecList>
                {
                  spec.node.items.map((item, i) => {
                    return (
											<li key={i}>
												<Check color="#FDB515" strokeWidth="5" />
												<ItemName>{item.name}</ItemName>
											</li>
										)
                  })
                }
              </SpecList>
            </SpecContainer>
          )
        })}
      </SpecsContainer>
    </SpecsSection>
  )
}

export default Specifications
