import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Wave from '../../components/svg/wave'
import {
	FullSection,
	TwoBannerContainer,
	PowerHouseContainer,
	WaveLogoContainer,
	TwoBannerHeading2,
	WhichModelContainer,
	WhichModelHead,
	WhichModelText,
	WhichModelLink,
} from './styles'

const options = {
	renderNode: {
		[BLOCKS.HEADING_2]: (node, children) => (
			<TwoBannerHeading2>
				{children[0].split('\n').map((text, i) => {
					return (
						<>
							{text}
							<br />
						</>
					)
				})}
			</TwoBannerHeading2>
		),
	},
}

const whichOptions = {
	renderNode: {
		[BLOCKS.HEADING_2]: (node, children) => (
			<WhichModelHead>{children}</WhichModelHead>
		),
		[BLOCKS.PARAGRAPH]: (node, children) => (
			<WhichModelText>{children}</WhichModelText>
		),
	},
}

const TwoBanner = ({ powerhouse, whichmodel }) => {
	const { mainActionText, mainActionUrl } = whichmodel
	return (
		<FullSection>
			<TwoBannerContainer>
				<PowerHouseContainer>
					<WaveLogoContainer>
						<Wave size="220" />
					</WaveLogoContainer>
					{documentToReactComponents(powerhouse.description.json, options)}
				</PowerHouseContainer>
				<WhichModelContainer>
					{documentToReactComponents(whichmodel.description.json, whichOptions)}
					<WhichModelLink to={'/' + mainActionUrl}>
						{mainActionText}
					</WhichModelLink>
				</WhichModelContainer>
			</TwoBannerContainer>
		</FullSection>
	)
}

export default TwoBanner
