import React, { useRef, useState } from 'react'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { PlayCircle, PauseCircle } from 'react-feather'
import Wave from '../../components/svg/wave'
import {
	HeroSection,
	HeroItemsContainer,
	HeroButtons,
	HeroSeeAllLink,
	HeroSeeAll,
	HeroBookLink,
	HeroBook,
	Bold,
	Heading1,
	BannerText,
	DolphinLogo,
	WaveContainer,
	HeroLogos,
} from './styles'

const heroBannerOptions = {
	renderMark: {
		[MARKS.BOLD]: text => <Bold>{text}</Bold>,
	},
	renderNode: {
		[BLOCKS.HEADING_1]: (node, children) => <Heading1>{children}</Heading1>,
		[BLOCKS.PARAGRAPH]: (node, children) => <BannerText>{children}</BannerText>,
	},
}

const Hero = ({
	mainActionText,
	mainActionUrl,
	secondActionText,
	secondActionUrl,
	description,
	videourl,
	brandUrl,
	bannerSources,
}) => {
	const [control, setControl] = useState(false)
	const play = useRef(null)
	const playVidHandler = () => {
		setControl(true)
		play.current.play()
	}
	const pauseVidHandler = () => {
		setControl(false)
		play.current.pause()
	}
	return (
		<HeroSection sources={bannerSources}>
			<video ref={play} width="100%" height="100%" loop>
				<source src={`${videourl?.file?.url}`} type="video/mp4"></source>
			</video>
			<HeroItemsContainer sources={control === true ? false : bannerSources}>
				{control === false ? (
					<>
						<HeroLogos>
							<DolphinLogo src={brandUrl?.file?.url} alt={brandUrl?.description}></DolphinLogo>
							<WaveContainer>
								<Wave size="170" />
							</WaveContainer>
						</HeroLogos>
						{documentToReactComponents(description.json, heroBannerOptions)}
						<HeroButtons>
							{mainActionUrl != undefined || mainActionUrl != null ? (
								<HeroSeeAllLink to={'/' + mainActionUrl}>
									{mainActionText != undefined || mainActionText != null ? (
										<HeroSeeAll>{mainActionText}</HeroSeeAll>
									) : (
										''
									)}
								</HeroSeeAllLink>
							) : (
								''
							)}
							{secondActionUrl != undefined || secondActionUrl != null ? (
								<HeroBookLink to={'/' + secondActionUrl}>
									{secondActionText != undefined || secondActionText != null ? (
										<HeroBook>{secondActionText}</HeroBook>
									) : (
										''
									)}
								</HeroBookLink>
							) : (
								''
							)}
						</HeroButtons>
					</>
				) : (
					''
				)}
				{control === false ? (
					<PlayCircle
						onClick={playVidHandler}
						className="play"
						color="#fff"
						width="70"
						height="70"
					/>
				) : (
					<PauseCircle
						onClick={pauseVidHandler}
						className="pause"
						color="#fff"
						width="70"
						height="70"
					/>
				)}
			</HeroItemsContainer>
		</HeroSection>
	)
}

export default Hero
