import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import axios from 'axios'
import qs from 'qs'
import tw, { css, styled } from 'twin.macro'

import filterLocale from '../utils/filter-locale'
import { 
	StyledForm,
	HomeButtonRow,
	HomeButton,
	FieldRow,
	FieldWrap,
	Required,
	StyledError,
} from '../form/styles'
import { Formik, Field } from 'formik'
import assign from 'lodash.assign'
import { flat, validationSwitch, validationSchema } from '../form/utils'
import FormFieldGroup from '../form/FormFieldGroup'
import FormFieldSingle from '../form/FormFieldSingle'
import { FullSection, RequestSection, Title, CheckboxFormContainer } from './styles'
import PopUp from '../modal/forms'
import { Lottie } from '@crello/react-lottie'
import { CustomCheckboxInput } from '@reach/checkbox'
import * as animationCheckbox from '../../animations/checkbox.json'
import localeCheck from '../utils/locale-check' 

const QuoteRequest = ({ locale }) => {
	const [showDialog, setShowDialog] = React.useState(false)
	const open = () => setShowDialog(true)
	const close = () => setShowDialog(false)
	const { quoterequest } = useStaticQuery(graphql`
		query quoteRequestQuery {
			quoterequest: allContentfulForm(
				filter: { title: { eq: "Request a quote" } }
			) {
				edges {
					node {
						title
						description
						endpoint
						node_locale
						items {
							... on ContentfulFormField {
								helpText
								inputAutocomplete
								inputId
								inputName
								inputPlaceholder
								inputRequired
								inputType
								textareaCols
								textareaRows
								icon
								validationText
								width
								options
								node_locale
								label
							}
							... on ContentfulFormGroup {
								title
								items {
									width
									validationText
									textareaRows
									textareaCols
									options
									node_locale
									label
									inputType
									inputRequired
									inputPlaceholder
									inputName
									inputId
									inputAutocomplete
									icon
									helpText
								}
							}
						}
					}
				}
			}
		}
	`)

	const [{ node: request }] = filterLocale(quoterequest.edges, locale)
	// console.log(request.endpoint)

	const valuesMapped = flat(request.items).map(item => ({
		[item.inputName]: '',
	}))
	valuesMapped.push({ readandagree: false })
	const validationMapped = flat(request.items).map(item => ({
		[item.inputName]: validationSwitch(item.inputName, item.validationText),
	}))
	validationMapped.push({ readandagree: validationSwitch('readandagree') })
	/** New Object from mapped Array */
	const valuesObject = assign({}, ...valuesMapped)
	const validationObject = assign({}, ...validationMapped)

	const defaultLocale = 'en-AU'
	const checkedLocale = localeCheck(locale, defaultLocale)
	return (
		<FullSection>
			<RequestSection>
				<Title>{request.title}</Title>
				<Formik
					initialValues={valuesObject}
					validationSchema={validationSchema(validationObject)}
					onSubmit={async (values, { setSubmitting, resetForm }) => {
						setSubmitting(true)
						const url =
							'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8'
						const body = {
							origin: 'Contact Us WebSite',
							orgid: '00D58000000JkgU',
							retURL:
								'https://maytronics.com.au/dolphin/commercial/?form=commercial',
							'00N4H00000E2wq9': 'https://maytronics.com.au/dolphin/commercial',
							subject: 'Commercial - Quote',
							name: `${values.firstName} ${values.lastName}`,
							phone: values.phone,
							'00N4H00000E2wqB': values.streetAddress,
							'00N4H00000E2wq6': values.city,
							'00N4H00000E2wqA': values.state,
							'00N4H00000E2wqC': values.postCode,
							'00N4H00000E2wq7': 'Australia',
							description: values.message,
							recordType: '0124H0000006NyI',
						}
						locale === 'en-AU' && (await axios.post(url, qs.stringify(body)))
						await axios.post('/.netlify/functions/graphql', {
							query: `
								mutation createForm(
									$formName: String!
									$subject: String
									$firstName: String
									$lastName: String
									$phone: String
									$address: AddressInput
									$message: String
									$locale: String
								) {
									createForm(
										formName: $formName
										subject: $subject
										firstName: $firstName
										lastName: $lastName
										phone: $phone
										address: $address
										message: $message
										locale: $locale
									) {
										_id
									}
								}
							`,
							variables: {
								locale,
								formName: 'commercial',
								subject: 'commercial-quote',
								firstName: values.firstName,
								lastName: values.lastName,
								phone: values.phone,
								address: {
									fullAddress: `${values.streetAddress}, ${values.city} ${values.state} ${values.postCode}`,
									line1: values.streetAddress,
									city: values.city,
									state: values.state,
									postalCode: values.postCode,
									country: locale === 'en-AU' ? 'Australia' : 'New Zealand',
								},
								message: values.message,
							},
						})
						resetForm()
						setSubmitting(false)
						return open()
					}}
				>
					{({ isSubmitting, errors, touched, values }) => (
						<StyledForm>
							{request.items.map((item, index) => {
								if (item.items) {
									const { title, items } = item
									return (
										<FormFieldGroup
											key={title + index}
											color="#fff"
											{...{ items, errors, touched, values }}
										/>
									)
								} else {
									return (
										<FormFieldSingle
											key={item.inputName + index}
											overrideWidth="full"
											color="#fff"
											{...{
												item: { ...item, helpText: null },
												errors,
												touched,
												values,
											}}
										/>
									)
								}
							})}
							<FieldRow>
								<FieldWrap>
									<Field name="readandagree" type="checkbox">
										{({ field, form: { setFieldValue, setTouched } }) => (
											<div tw="flex w-full mb-4 items-center">
												<CheckboxFormContainer
													tw="w-6 h-6 cursor-pointer rounded-sm mr-2"
													onClick={() =>
														setFieldValue(
															'readandagree',
															field.checked ? false : true
														)
													}
													onKeyDown={() =>
														setFieldValue(
															'readandagree',
															field.checked ? false : true
														)
													}
												>
													<CustomCheckboxInput
														{...field}
														checked={field.checked}
														name="readandagree"
														type="checkbox"
													/>
													<Lottie
														playingState={'playing'}
														speed={1}
														direction={field.checked ? 1 : -1}
														width={24}
														height={24}
														tw="bg-white rounded"
														className="lottie-container checkbox"
														config={{
															animationData: animationCheckbox.default,
															loop: false,
															autoplay: true,
															renderer: 'svg',
														}}
														aria-hidden
													/>
												</CheckboxFormContainer>
												<p tw="text-sm font-display">I have read and I agree to <a tw="font-bold underline hover:no-underline" href={`${checkedLocale}/terms/`} target="_blank">Maytronics Terms &#38; Conditions</a> and <a tw="font-bold underline hover:no-underline" href={`${checkedLocale}/privacy/`} target="_blank">Privacy Policy</a> <Required>*</Required></p>
											</div>
										)}
									</Field>
									{touched['readandagree'] && errors['readandagree'] ? (
										<StyledError>{`▲ ` + errors['readandagree']}</StyledError>
									) : (
										<></>
									)}
								</FieldWrap>
							</FieldRow>
							<FieldRow>
								<FieldWrap>
									<Field name="updates" type="checkbox">
										{({ field, form: { setFieldValue, setTouched } }) => (
											<div tw="flex w-full mb-4 items-center">
												<CheckboxFormContainer
													tw="w-6 h-6 cursor-pointer rounded-sm mr-2"
													onClick={() =>
														setFieldValue(
															'updates',
															field.checked ? false : true
														)
													}
													onKeyDown={() =>
														setFieldValue(
															'updates',
															field.checked ? false : true
														)
													}
												>
													<CustomCheckboxInput
														{...field}
														checked={field.checked}
														name="updates"
														type="checkbox"
													/>
													<Lottie
														playingState={'playing'}
														speed={1}
														direction={field.checked ? 1 : -1}
														width={24}
														height={24}
														tw="bg-white rounded"
														className="lottie-container checkbox"
														config={{
															animationData: animationCheckbox.default,
															loop: false,
															autoplay: true,
															renderer: 'svg',
														}}
														aria-hidden
													/>
												</CheckboxFormContainer>
												<p tw="text-sm font-display">I wish to receive updates and promotions from Maytronics.</p>
											</div>
										)}
									</Field>
								</FieldWrap>
							</FieldRow>
							<HomeButtonRow>
								<HomeButton type="submit" disabled={isSubmitting}>
									{isSubmitting ? 'Sending...' : 'Send Request'}
								</HomeButton>
							</HomeButtonRow>
						</StyledForm>
					)}
				</Formik>
				<PopUp showDialog={showDialog} close={close} />
			</RequestSection>
		</FullSection>
	)
}

QuoteRequest.propTypes = {
	locale: PropTypes.string,
}

export default QuoteRequest
