import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Section, Heading2, Text } from './styles'

const PageDescription = ({ pagebody }) => {
	const options = {
		renderNode: {
			[BLOCKS.HEADING_2]: (node, children) => {
				return <Heading2>{children}</Heading2>
			},
			[BLOCKS.PARAGRAPH]: (node, children) => {
				return <Text>{children}</Text>
			},
		},
	}

	return (
    <Section>
			{documentToReactComponents(pagebody.json, options)}
    </Section>
  )
}

export default PageDescription
