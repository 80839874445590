import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { Query } from 'react-apollo'
import { Client } from '../../apollo/client'
import Spinner from '../utils/spinner'
import isEmpty from 'lodash.isempty'
import { navigate } from 'gatsby'
import { onProductClick } from '../utils/analytics'
import {
	MainSection,
	ProductsContainer,
	Main,
	ProductsInnerContainer,
	TrialRequest,
	Request,
	ItemDetails,
	Continue,
	NullText,
	MainItemContainer,
	ItemContainer,
	ItemInnerContainer,
	ItemImageContainer,
	ItemImage,
	DetailsContainer,
	ProductName,
	Feature,
	SubItemContainer,
	SubItemsContainer,
	SubItemsInnerContainer,
	SubItemsDetails,
	SubItemBtns,
	SubItemImageContainer,
	SubItemDetails,
	SubTrialRequest,
	Compare,
	MainDetailsContainer,
	MainProductName,
	MainFeature,
} from './styles'

const Products = ({ locale, productImage, checkedLocale }) => {
	const filterAllByLocale = (items, locale) => {
		return items.filter((item, i) => {
			return item.node.node_locale === locale && item.node.series === 'Wave'
		})
	}

	const filterByCountry = (items, locale) => {
		if ( items.length > 0 ) {
			return items.filter((x, i) => {
				if ( Object.keys(x).length === 0) {
					return null
				} else {
					const localeSplit = locale.split('-')
					const langCode = localeSplit[1]
					const check = x.countryAvailability.indexOf(langCode)
					return check >= 0 ? x : null
				}
			})
		} else {
			return []
		}
	}

	const isBrowser = typeof window !== 'undefined'
	const [items, setItems] = useState([])
	useEffect(() => {
		/**
		 * Google Analytics - Tag Manager - Enhanced Ecommerce
		 * Measuring product impressions for every search result change
		 * */
		const floatPrice = price =>
			price >= 1 ? parseFloat(price).toFixed(2) : parseFloat(0).toFixed(2)
		let impressions =
			items.length >= 1 &&
			items.map((item, index) => {
				return {
					name: item.seoTitle, // the name of the product, including Title and Subtitle
					id: item.sku, // product serial number
					price: Number(floatPrice(item.price)), // actual price per unit or in case of fixed price discounts the discounted price
					brand: item.brand, // product brand
					category: [item.categories && item.categories.title].toString(), // Dolphin domestic, Dolphin commercial, mineral swim, ozone swim
					list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
					position: index + 1, // the position of the product within the list
				}
			})
		if (isBrowser && window.dataLayer && items.length >= 1) {
			window.dataLayer.push({
				event: 'productImpression',
				ecommerce: {
					currencyCode: 'AUD',
					impressions: [
						...impressions,
						{
							name: 'Poseidon System',
							id: 'POSEIDON',
							price: Number(floatPrice(245000.0)),
							brand: 'Poseidon',
							category: 'Pool Safety - Commercial',
							list: 'Category List',
							position: impressions.length + 1,
						},
					],
				},
			})
		}
	}, [items])
	return (
		<MainSection>
			<ProductsContainer>
				<Query
					query={APOLLO_PRODUCT_LINE_QUERY}
					variables={{ series: 'Wave', locale: locale }}
					client={Client}
				>
					{({ data, loading, error }) => {
						if (loading) {
							return <Spinner />
						}
						if (error) {
							return <NullText>Something Went Wrong!</NullText>
						}

						const {
							productCollection: { items },
						} = data
						setItems(items)
						const filteredImages = filterAllByLocale(productImage.edges, locale)
						let overrideItems = items.map(item => {
							return { ...item, val: 1 }
						})
						const [{ ...firstItem }, ...rest] = overrideItems
						const mainItems = filterByCountry([firstItem], locale)
						const otherItems = filterByCountry([...rest], locale)
						const remainder = [...rest].length % 3
						return (
							<Main>
								<ProductsInnerContainer
									wrap={items.length > 3 ? true : undefined}
								>
									<MainItemContainer>
										{items.length > 0 ? mainItems.length > 0 ? (
											mainItems.map((item, i) => {
												if (!isEmpty(item)) {
													let title = item.seoTitle
													let titleSplit = title.split(' ')
													let productBrand = titleSplit.find(
														ttl =>
															ttl === 'Dolphin' ||
															ttl === 'Mineral' ||
															ttl === 'Ozone'
													)
													let pBrand =
														productBrand === 'Dolphin'
															? productBrand
															: productBrand === 'Mineral'
															? 'Mineral Swim'
															: productBrand === 'Ozone'
															? 'Ozone Swim'
															: ''

													let itemName = item.seoTitle.split(pBrand).slice(1)

													const filterImg = img => {
														return img.filter(x => {
															return x.node.slug === item.slug ? x : ''
														})
													}
													const filteredByslug = filterImg(filteredImages)
													return (
														<ItemContainer key={i}>
															<ItemInnerContainer>
																<ItemImageContainer
																	onClick={() => {
																		onProductClick(
																			{
																				name: item.seoTitle,
																				sku: item.sku,
																				price: Number(item.price * 100),
																				brand: item.brand,
																				category: [
																					item.categories &&
																						item.categories.title,
																				].toString(),
																				list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																				position: i + 1, // the position of the product within the list
																			},
																			'Category List',
																			i,
																			navigate(`${checkedLocale}/product/${item.slug}`)
																		)
																	}}
																>
																	<ItemImage>
																		<source
																			srcSet={
																				filteredByslug[0].node.images[0].fluid
																					.srcWebp
																			}
																			type="image/webp"
																		/>
																		<source
																			srcSet={`${
																				filteredByslug[0].node.images[0].fluid.src.split(
																					'?'
																				)[0]
																			}?fm=png&fl=png8&fit=pad&w=600&h=400`}
																		/>
																		<img
																			src={
																				filteredByslug[0].node.images[0].fluid
																					.src
																			}
																			alt={
																				filteredByslug[0].node.images[0]
																					.description
																			}
																		/>
																	</ItemImage>
																</ItemImageContainer>
																<MainDetailsContainer>
																<DetailsContainer>
																	<MainProductName>{itemName}</MainProductName>
																	{item.featuresCollection.items.map(
																		(feature, i) => {
																			return (
																				<MainFeature key={`${feature.title}${i}`}>
																					{feature.title}
																				</MainFeature>
																			)
																		}
																	)}
																</DetailsContainer>
																<TrialRequest
																	to={`${checkedLocale}/support/contact/?subject=commercial+trial+${encodeURIComponent(
																		item.model.toLowerCase()
																	)}#contact-form`}
																>
																	<Request>{locale === 'en-AU' ? 'Request a Trial' : 'Enquire now'}</Request>
																</TrialRequest>
																<ItemDetails
																	onClick={() => {
																		onProductClick(
																			{
																				name: item.seoTitle,
																				sku: item.sku,
																				price: Number(item.price * 100),
																				brand: item.brand,
																				category: [
																					item.categories &&
																						item.categories.title,
																				].toString(),
																				list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																				position: i + 1, // the position of the product within the list
																			},
																			'Category List',
																			i,
																			navigate(`${checkedLocale}/product/${item.slug}/`)
																		)
																	}}
																>
																	<Continue>View Product</Continue>
																</ItemDetails>
																</MainDetailsContainer>
															</ItemInnerContainer>
														</ItemContainer>
													)
												}
											})
										) : <NullText>No items can be found!</NullText> : (
											<NullText>No items can be found!</NullText>
										)}
									</MainItemContainer>
									{otherItems.length > 0 ? (
										<SubItemContainer>
											{items.length > 0
												? otherItems.map((item, i) => {
														if (!isEmpty(item)) {
															let title = item.seoTitle
															let titleSplit = title.split(' ')
															let productBrand = titleSplit.find(
																ttl =>
																	ttl === 'Dolphin' ||
																	ttl === 'Mineral' ||
																	ttl === 'Ozone'
															)
															let pBrand =
																productBrand === 'Dolphin'
																	? productBrand
																	: productBrand === 'Mineral'
																	? 'Mineral Swim'
																	: productBrand === 'Ozone'
																	? 'Ozone Swim'
																	: ''

															let itemName = item.seoTitle
																.split(pBrand)
																.slice(1)

															const filterImg = img => {
																return img.filter(x => {
																	return x.node.slug === item.slug ? x : ''
																})
															}

															const filteredByslug = filterImg(filteredImages)
															return (
																<SubItemsContainer key={i} wid={remainder === 1}>
																	<SubItemsInnerContainer
																		fullHeight={otherItems.length > 1}
																	>
																		<SubItemsDetails
																			fullHeight={otherItems.length > 1}
																		>
																			<SubItemImageContainer
																				onClick={() => {
																					onProductClick(
																						{
																							name: item.seoTitle,
																							sku: item.sku,
																							price: Number(item.price * 100),
																							brand: item.brand,
																							category: [
																								item.categories &&
																									item.categories.title,
																							].toString(),
																							list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
																							position:
																								i + 1 + mainItems.length, // the position of the product within the list
																						},
																						'Category List',
																						i + mainItems.length,
																						navigate(`${checkedLocale}/product/${item.slug}`)
																					)
																				}}
																			>
																				<ItemImage>
																					<source
																						srcSet={
																							filteredByslug[0].node.images[0]
																								.fluid.srcWebp
																						}
																						type="image/webp"
																					/>
																					<source
																						srcSet={`${
																							filteredByslug[0].node.images[0].fluid.src.split(
																								'?'
																							)[0]
																						}?fm=png&fl=png8&fit=pad&w=600&h=400`}
																					/>
																					<img
																						src={
																							filteredByslug[0].node.images[0]
																								.fluid.src
																						}
																						alt={
																							filteredByslug[0].node.images[0]
																								.description
																						}
																					/>
																				</ItemImage>
																			</SubItemImageContainer>
																			<DetailsContainer>
																				<ProductName>{itemName}</ProductName>
																				{item.featuresCollection.items.map(
																					(feature, i) => {
																						return (
																							<Feature
																								key={`${feature.title}${i}`}
																							>
																								{feature.title}
																							</Feature>
																						)
																					}
																				)}
																			</DetailsContainer>
																			<SubItemBtns
																				marginAuto={otherItems.length > 1}
																			>
																				<SubTrialRequest
																					to={`${checkedLocale}/support/contact/?subject=commercial+trial+${encodeURIComponent(
																						item.model.toLowerCase()
																					)}#contact-form`}
																				>
																					<Request>{locale === 'en-AU' ? 'Request a Trial' : 'Enquire now'}</Request>
																				</SubTrialRequest>
																				<SubItemDetails
																					onClick={() => {
																						onProductClick(
																							{
																								name: item.seoTitle,
																								sku: item.sku,
																								price: Number(item.price * 100),
																								brand: item.brand,
																								category: [
																									item.categories &&
																										item.categories.title,
																								].toString(),
																								list: 'Category List',
																								position:
																									i + 1 + mainItems.length,
																							},
																							'Category List',
																							i + mainItems.length,
																							navigate(`${checkedLocale}/product/${item.slug}/`)
																						)
																					}}
																				>
																					<Continue>View Product</Continue>
																				</SubItemDetails>
																			</SubItemBtns>
																		</SubItemsDetails>
																	</SubItemsInnerContainer>
																</SubItemsContainer>
															)
														}
												  })
												: null}
										</SubItemContainer>
									) : (
										''
									)}
									{
										mainItems.length > 0 ? (
											<Compare href={`${checkedLocale}/product-comparison#commercial`}>
												Compare Models
											</Compare>
										) : ''
									}
								</ProductsInnerContainer>
							</Main>
						)
					}}
				</Query>
			</ProductsContainer>
		</MainSection>
	)
}

const APOLLO_PRODUCT_LINE_QUERY = gql`
	query prodLineQuery($series: String!, $locale: String!) {
		productCollection(where: { series: $series }, locale: $locale, order: [popularity_DESC]) {
			items {
				seoTitle
				seoDescription
				slug
				sku
				price
				model
				brand
				active
				series
				countryAvailability
				categories {
					title: seoTitle
					slug
				}
				shippingClass
				imagesCollection(limit: 1) {
					items {
						url
						description
					}
				}
				featuresCollection(limit: 3) {
					items {
						title
					}
				}
			}
		}
	}
`

export default Products
