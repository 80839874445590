import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { navigate } from 'gatsby'
import { onProductClick } from '../utils/analytics'
import {
	PoseidonSection,
	PoseidonContainer,
	PoseidonImage,
	PoseidonDescription,
	Statsbg,
	StatsContainer,
	StatContainer,
	StatHead,
	StatDescription,
	LearnMore,
} from './styles'

const Poseidon = ({ poseidon, stats }) => {
	const option = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<PoseidonDescription>{children}</PoseidonDescription>
			),
		},
	}

	const statoption = {
		renderNode: {
			[BLOCKS.HEADING_3]: (node, children) => <StatHead>{children}</StatHead>,
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<StatDescription>{children}</StatDescription>
			),
		},
	}

	return (
		<PoseidonSection id="poseidon" bg={poseidon.backgroundImage.file.url}>
			<PoseidonContainer>
				<PoseidonImage
					src={poseidon.image.file.url}
					alt={poseidon?.image?.description}
				/>
				{documentToReactComponents(poseidon.description.json, option)}
				<Statsbg>
					<StatsContainer>
						{stats[0].node.items.map((stat, i) => {
							return (
								<StatContainer key={i}>
									{documentToReactComponents(stat.body.json, statoption)}
								</StatContainer>
							)
						})}
					</StatsContainer>
				</Statsbg>

				<LearnMore
					onClick={() => {
						onProductClick(
							{
								name: 'Poseidon System',
								sku: 'POSEIDON',
								price: 245000.0,
								brand: 'Poseidon',
								category: 'Pool Safety - Commercial',
								list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
								position: 0 + 1, // the position of the product within the list
							},
							'Category List',
							0
						)
					}}
					href={poseidon.mainActionUrl}
					target="_blank"
					rel="noopener noreferrer"
				>
					{poseidon.mainActionText}
				</LearnMore>
			</PoseidonContainer>
		</PoseidonSection>
	)
}

export default Poseidon
